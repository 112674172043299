import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import 'whatwg-fetch'
import Layout from '../shared/Layout'
import ArticleTiles from '../components/ArticleTiles'
import LandingSubscriptionBottom from '../components/LandingSubscriptionBottom'

const { Heading } = Typography
const { Row, Col } = Grid

const Search = ({ pageContext, location, data }) => {
  const [articles, setArticles] = useState([])
  const [isSearchDone, setIsSearchDone] = useState(false)
  const parsedParams = queryString.parse(location.search)
  const { footer, lang } = pageContext
  const {
    heading,
    subHeading,
    inputPlaceholder,
    buttonText,
  } = data.datoCmsSubscriptionBanner

  useEffect(() => {
    fetch('https://graphql.datocms.com/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.GATSBY_DELIVERY_API_TOKEN}`,
      },
      body: JSON.stringify({
        query: `{
          allArticles(filter: {body: {matches: {pattern: "${parsedParams.term}"}}}, locale: en_CA) {
            slug
            id
            title
            image {
              responsiveImage {
                alt
                aspectRatio
                base64
                srcSet
                src
                sizes
              }
            }
            category {
              name
              slug
            }
            manualReadTime
          }
        }`,
      }),
    })
      .then(res => res.json())
      .then(res => {
        setArticles(res.data.allArticles)
        setIsSearchDone(true)
      })
      .catch(error => {
        console.log(error)
      })
  }, [parsedParams.term])

  return (
    <Layout footer={footer} location={location}>
      <Box marginBottom={[40, 40, 64, 64, 80]} marginTop={[48, 48, 72, 72, 88]}>
        <Grid>
          <Row horizontalAlign="center">
            <Col>
              {articles.length > 0 && isSearchDone && (
                <Heading variant="--heading-1">
                  Search result for “{parsedParams.term}”
                </Heading>
              )}
              {articles.length === 0 && isSearchDone && (
                <Heading variant="--heading-1">
                  No search results available for “{parsedParams.term}”
                </Heading>
              )}
            </Col>
          </Row>
        </Grid>
      </Box>
      <Box marginBottom={[72, 72, 80, 80, 144]}>
        <ArticleTiles articles={articles} lang={lang} />
      </Box>
      <LandingSubscriptionBottom
        heading={heading}
        subHeading={subHeading}
        inputPlaceholder={inputPlaceholder}
        buttonText={buttonText}
      />
    </Layout>
  )
}

export const query = graphql`
  query banner {
    datoCmsSubscriptionBanner(entrytitle: { regex: "/Bottom/" }) {
      heading
      subHeading
      inputPlaceholder
      buttonText
    }
  }
`

export default Search
